import "./import/modules";
import "./import/components";
import filter from "./import/filter";
import modals from "../blocks/components/modal/modal";
import tabs from "../blocks/components/tabs/tabs";
import formatPrices from "./import/price";

// import Swiper, { Navigation, Pagination } from "swiper";
import { Carousel } from "@fancyapps/ui";

document.addEventListener("DOMContentLoaded", () => {
    const mainMenuBtns = document.querySelectorAll("[data-main-menu-btn]");
    const mainMenu = document.querySelector("[data-main-menu]");
            
    if (mainMenuBtns && mainMenu) {
        mainMenuBtns.forEach(btn => {
            btn.addEventListener("click", (e) => {
                console.log("click", mainMenu);
                mainMenu.classList.toggle("visible");
            });
        });
    }

    const body = document.body;
    let scrollTimer;

    window.addEventListener("scroll", function() {
        clearTimeout(scrollTimer);
        if(!body.classList.contains("disable-hover")) {
            body.classList.add("disable-hover");
        }
        
        scrollTimer = setTimeout(function(){
            body.classList.remove("disable-hover");
        }, 300);
    }, false);
      
    document.addEventListener("click", function(e) {
        const selectAll = e.target.closest("[data-select-all");
        if (selectAll) {
            const checkbox = selectAll.querySelector("input[type='checkbox']");
            if (!checkbox) return;
            const selections = document.querySelectorAll("[data-select-content] input[type='checkbox']");

            checkbox.checked = ( checkbox.checked ? false : true);
            if (selections) {
                selections.forEach(check => {
                    check.checked = checkbox.checked;
                });
            }   
        }

        const shrinkText = e.target.closest("[data-text-shrink]");
        if (shrinkText) {
            toggleClass(shrinkText, "is-shrinked", "");
        }
    });

    document.addEventListener("focus", function(e) {
        const input = e.target.closest("input:not([type=radio])");
        document.querySelectorAll("input, textarea").forEach(input => {
            input.parentElement.classList.remove("has-focus");
        });
        if (input) {
            input.parentElement.classList.add("has-focus");
        }
    }, true);

    document.addEventListener("blur", function() {
        document.querySelectorAll("input").forEach(input => {
            input.parentElement.classList.remove("has-focus");
        });
    }, true);

    modals();
    tabs();
    formatPrices();

    const anchors = document.querySelectorAll("a[href^=\"#\"]");
    if (anchors) {
        anchors.forEach(anchor => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
        
                document.querySelector(this.getAttribute("href")).scrollIntoView({
                    behavior: "smooth"
                });
            });
        });
    }
    

    const togglePwdType = (el) => {
        if (el.type === "password") {
            el.type = "text";
        } else {
            el.type = "password";
        }
    };

    const toggleClass = (el, cn1, cn2) => {
        if (cn2 !== "") {
            if (el.classList.contains(cn1)) {
                el.classList.remove(cn1);
                el.classList.add(cn2);
            } else {
                el.classList.remove(cn2);
                el.classList.add(cn1);
            }
        }
        else {
            el.classList.toggle(cn1);
        }
    };

    const togglePwdVisibility = () => {
        const pwdInputs = document.querySelectorAll("[data-password]");
        pwdInputs.forEach(item => {
            const btn = item.querySelector("button");
            btn.addEventListener("click", function(e) {
                e.preventDefault();
                const input = this.parentElement.querySelector("input");
                togglePwdType(input);
                toggleClass(btn, "button_toggle_on", "button_toggle_off");
            });
        });
    };
    togglePwdVisibility();

    const popularSlider = document.querySelector(".popular-slider");
    console.log("popularSlider", popularSlider);

    if (popularSlider) {
        new Carousel(popularSlider, {
            center : false,
            slidesPerPage: 1,
            Navigation: {
                prevTpl: `<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="46" height="46" rx="23" transform="matrix(-1 0 0 1 46 0)" fill="white" fill-opacity="0.4"/>
                <path d="M27 14.5L19 23L27 31.5" stroke="url(#paint0_angular_651_11027)" stroke-width="2"/>
                <defs>
                <radialGradient id="paint0_angular_651_11027" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23 23.0153) rotate(98.427) scale(27.2792 4.60779)">
                <stop stop-color="#FF7690"/>
                <stop offset="1" stop-color="#DE0D2C"/>
                </radialGradient>
                </defs>
                </svg>`,
                nextTpl: `<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="46" height="46" rx="23" fill="white" fill-opacity="0.4"/>
                <path d="M19 14.5L27 23L19 31.5" stroke="url(#paint0_angular_651_11029)" stroke-width="2"/>
                <defs>
                <radialGradient id="paint0_angular_651_11029" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23 23.0153) rotate(81.573) scale(27.2792 4.60779)">
                <stop stop-color="#FF7690"/>
                <stop offset="1" stop-color="#DE0D2C"/>
                </radialGradient>
                </defs>
                </svg>
                `
            }
        });
    }

    const mainMapContainer = document.getElementById("map-main");
    if(mainMapContainer) {
        let mapMain;
    
        // eslint-disable-next-line no-undef
        ymaps.ready(init);
    
        // eslint-disable-next-line no-inner-declarations
        function init () {
            mapMain = new ymaps.Map(mainMapContainer, {
                center: [59.936966, 30.371976], 
                zoom: 14,
                controls: []
            }, {
                autoFitToViewport: "always"
            });

            const shopMark = new ymaps.Placemark(mapMain.getCenter(), {
            }, {
                iconLayout: "default#image",
                iconImageHref: "/img/svg/marker.svg",
                iconImageSize: [98, 87],
                iconImageOffset: [-49, -87]
            });

            mapMain.geoObjects.add(shopMark);
        }
    }

    const contactsMapContainer = document.getElementById("map-contacts");
    if(contactsMapContainer) {
        let mapContacts;
    
        // eslint-disable-next-line no-undef
        ymaps.ready(init);
    
        // eslint-disable-next-line no-inner-declarations
        function init () {
            mapContacts = new ymaps.Map(contactsMapContainer, {
                center: [59.936966, 30.371976], 
                zoom: 14,
                controls: []
            }, {
                autoFitToViewport: "always"
            });

            const shopMark = new ymaps.Placemark(mapContacts.getCenter(), {
            }, {
                iconLayout: "default#image",
                iconImageHref: "/img/svg/marker.svg",
                iconImageSize: [98, 87],
                iconImageOffset: [-49, -87]
            });

            mapContacts.geoObjects.add(shopMark);
        }
    }

    filter();
    
    let text = document.getElementById("copy-text"); 
    if (text) {
        text = text.innerText.split("\n").filter(item => item.length !== 0).map((item, idx) => {
            if (idx % 2 !== 1) {
                return `${item}: `;
            } 
            return `${item}
    `;
        }).join("");
        const copyContent = async () => {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error("Failed to copy: ", err);
            }
        };
    
        document.getElementById("copy-text-btn").addEventListener("click", () => {
            copyContent();
        });
    }
    
});
