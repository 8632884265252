import items from "../data/items";

export default () => {
    window.items = items;
    console.log("items:", window.items);

    const filters = document.querySelectorAll("[data-filter]");
    console.log("filters:", filters);
}; 

