const getScrollbarWidth = () => {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar";
    document.body.appendChild(outer);
    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = "scroll";
    const inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);        
    const widthWithScroll = inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
};

const stopScroll = () => {
    document.body.classList.add("no-scroll");
    document.body.style.paddingRight = getScrollbarWidth()+"px";
};

const enableScroll = () => {
    if (document.body.classList.contains("no-scroll")) {
        document.body.classList.remove("no-scroll");
        document.body.style.paddingRight = 0;
    }
};

export default () => {
    const modalBtns = document.querySelectorAll("[data-modal-show]");

    modalBtns.forEach(btn => {
        btn.addEventListener("click", function() {
            const modalId = btn.dataset.modalId;
            const modal = document.getElementById(modalId);
            const modals = document.querySelectorAll("[data-modal");

            if (modal.classList.contains("hidden")) {
                if ([...modals].filter(m => m.id !== modalId).length) {
                    modals.forEach(m => m.classList.add("hidden"));
                }
                modal.classList.remove("hidden");
                stopScroll();
            } else {
                modal.classList.add("hidden");
                this.blur();
                enableScroll();
            }
        });
    });

    document.addEventListener("click", function(e) {
        const closer = e.target.closest("[data-modal-close");
        if (e.target.closest("[data-modal-close")) {
            const modal = closer.closest(".modal");
            modal.classList.add("hidden");
            enableScroll();
            closer.blur();
        }
    });

    document.addEventListener("keyup", function(e) {
        if (e.key === "Escape") {
            document.querySelectorAll(".modal").forEach(modal => {
                if (!modal.classList.contains("hidden")) {
                    modal.classList.add("hidden");
                    enableScroll();
                    document.activeElement.blur();
                }
            });
        }
    });
};