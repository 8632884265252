export default () => {
    const tabBlocks = document.querySelectorAll("[data-tabs]");

    tabBlocks.forEach(tabBlock => {
        tabBlock.addEventListener("click", e => {
            const tab = e.target.closest("[data-tab-id]");
            if (tab) {
                tabBlock.querySelectorAll("[data-tab-id]").forEach(otherTab => otherTab.classList.remove("is-active"));
                tab.classList.add("is-active");

                const container = tabBlock.querySelector(`[data-tab=${tab.dataset.tabId}]`);
                console.log("container", container);
                tabBlock.querySelectorAll("[data-tab]").forEach(otherContainer => otherContainer.classList.remove("is-active"));
                container.classList.add("is-active");
            }
        });
    });

};