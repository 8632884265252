export default [{
    id: "1",
    type: "Clothes",
    name: "Куртка Alpine Spirit",
    brand: "Alpine",
    price: 18000,
    sizes: [
        "s", "m", "l"
    ],
    gen: "u"
}, {
    id: "2",
    type: "Boots",
    name: "Мотоботы Spirit",
    brand: "Alpine",
    price: 6000,
    sizes: [
        "40", "41", "42", "45"
    ],
    gen: "u"
}, {
    id: "3",
    type: "Boots",
    name: "Мотоботы",
    brand: "Alpine Forseer",
    price: 7500,
    sizes: [
        "38", "40", "43", "45"
    ],
    gen: "u"
}, {
    id: "4",
    type: "Clothes",
    name: "Комбинезон Alpine Forseer",
    brand: "Alpine",
    price: 18000,
    sizes: [
        "s", "m", "l"
    ],
    gen: "u"
}];