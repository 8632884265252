let RussianRoubles = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0
});

export default () => {
    const prices = document.querySelectorAll("[data-price]");
    if (!prices) {
        return;
    }

    prices.forEach(price => {
        console.log(price);
        const priceText = price.innerText;
        console.log(priceText);
        price.innerText = RussianRoubles.format(priceText);
        console.log("after", price.innerText);
    });
};